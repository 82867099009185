@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure there is no border or margin causing the line */
.sidebar {
  border-right: none; /* Ensure no border */
}

body.dark .sidebar {
  border-right: none; /* Ensure no border in dark mode */
}

.flex-grow {
  transition: margin-left 0.3s ease;
}

.sidebar-open {
  margin-left: 250px; /* Adjust based on sidebar width */
}

.prompt-text {
  font-size: 0.9rem; /* Smaller font size */
  font-style: italic; /* Italic text for the disclaimer */
}

/* Custom styles for dark mode */
body.dark .header {
  background-color: #1c1c1c;
  color: #e0e0e0;
}

body.dark .main-content {
  background-color: #2e2e2e;
  color: #e0e0e0;
}

body.dark .sidebar {
  background-color: #111111;
  color: #e0e0e0;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.codeblock {
  background-color: #1e1e1e;
  border-radius: 0.5rem;
  overflow: hidden;
}

.markdown-content ol, .markdown-content ul {
  padding-left: 1.5em;
}

/*
//Don't think this is needed anymore
.markdown-content li::marker {
  color: inherit;
  font-size: inherit;
}
*/

ol li p, ul li p {
  display: inline;
}

/* Dark mode scrollbar style */
:root {
  --scrollbar-thumb: #888; /* Light mode color */
  --scrollbar-track: #333; /* Light mode color */
}

.dark {
  --scrollbar-thumb: #555; /* Dark mode color */
  --scrollbar-track: #222; /* Dark mode color */
}

/* Scrollbar Styles */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
  border: 3px solid var(--scrollbar-track);
}

